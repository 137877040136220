exports.components = {
  "component---src-pages-app-tsx": () => import("./../../../src/pages/App.tsx" /* webpackChunkName: "component---src-pages-app-tsx" */),
  "component---src-pages-contact-tsx": () => import("./../../../src/pages/Contact.tsx" /* webpackChunkName: "component---src-pages-contact-tsx" */),
  "component---src-pages-description-tsx": () => import("./../../../src/pages/Description.tsx" /* webpackChunkName: "component---src-pages-description-tsx" */),
  "component---src-pages-footer-tsx": () => import("./../../../src/pages/Footer.tsx" /* webpackChunkName: "component---src-pages-footer-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-landing-tsx": () => import("./../../../src/pages/Landing.tsx" /* webpackChunkName: "component---src-pages-landing-tsx" */),
  "component---src-pages-navigation-tsx": () => import("./../../../src/pages/Navigation.tsx" /* webpackChunkName: "component---src-pages-navigation-tsx" */),
  "component---src-pages-service-card-tsx": () => import("./../../../src/pages/ServiceCard.tsx" /* webpackChunkName: "component---src-pages-service-card-tsx" */),
  "component---src-pages-services-tsx": () => import("./../../../src/pages/Services.tsx" /* webpackChunkName: "component---src-pages-services-tsx" */)
}

